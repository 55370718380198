import {useSettings as useYoshiSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import type {ISettingsParam, ISettingsSetter} from '@wix/yoshi-flow-editor/tpa-settings'
import {useSettingsBi, WidgetType} from '../../../settings-commons/hooks/use-settings-bi'

export const useSettings = componentSettings => {
  const settings = useYoshiSettings()
  const {debouncedEditorEventsWidgetSettingChanged} = useSettingsBi(WidgetType.EventsWidget)

  const get = (param: ISettingsParam) => {
    const value = settings.get(param)
    return value ?? (componentSettings.texts[param.key] || componentSettings[param.key])
  }

  const set: ISettingsSetter = (param, value) => {
    debouncedEditorEventsWidgetSettingChanged(param.key, value)
    settings.set(param, value)
  }

  return {
    ...settings,
    get,
    set,
  }
}
