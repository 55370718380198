import {
  IFRAME_BASE_URL,
  SCHEDULE_SECTION_ID,
  DASHBOARD_PATHS,
  getBillingUrl,
  isTicketed,
  getSlug,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {boLoadingStart101, pagesModalOpened, createManageEventClick, upgradeClick} from '@wix/bi-logger-events-users/v2'
import {UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {getActiveEvent, isDemoEvents} from '../selectors/events'
import {createAsyncAction} from '../services/redux-toolkit'
import {getGeneralFields} from '../selectors/bi'

export const OPEN_REGIONAL_SETTINGS_HELP = 'OPEN_REGIONAL_SETTINGS_HELP'
export const OPEN_BILLING_PAGE = 'OPEN_BILLING_PAGE'

const PAGES_MODAL_URL = `${IFRAME_BASE_URL}/html/widget/settings/modal/pages/widget`

export enum PAGES_MODAL_ORIGIN {
  TAB = 'main_page_tab',
  FOOTER = 'main_page_footer',
}

export const navigateToPage = createAsyncAction<void, string>('NAVIGATE_TO_PAGE', async (slug, {extra: {Wix}}) => {
  Wix.Data.Public.set('NAVIGATE_TO_PAGE', slug, {scope: 'APP'}, undefined, undefined)
  Wix.Utils.navigateToSection(
    {appDefinitionId: EVENTS_APP_ID, sectionId: 'events', state: slug, queryParams: ''},
    () => {},
  )
})

interface OpenFirstTimeCreateEventParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openFirstTimeCreateEvent = createAsyncAction<void, OpenFirstTimeCreateEventParams>(
  'OPEN_FIRST_TIME_CREATE_EVENT',
  async ({multiEventWidget, calendar, recurringFilter}, {dispatch, extra: {serverApi}}) => {
    dispatch(openCreateEvent({multiEventWidget, calendar, recurringFilter}))
    const payload = await serverApi.updateSiteSettings({firstTimeCompleted: true})
    dispatch({type: UPDATE_SITE_SETTINGS.SUCCESS, payload})
  },
)

interface OpenCreateEventParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openCreateEvent = createAsyncAction<void, OpenCreateEventParams>(
  'OPEN_CREATE_EVENT',
  async ({multiEventWidget, calendar, recurringFilter}, {dispatch, getState, extra: {bi}}) => {
    bi.report(
      createManageEventClick({
        tab_name: 'main',
        button_name: 'create_event',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )
    dispatch(openDashboard(DASHBOARD_PATHS.NEW_EVENT))
  },
)

export const openEventDetails = (eventId: string) =>
  eventId ? openDashboard(DASHBOARD_PATHS.EVENT_DETAILS(eventId)) : openDashboard(DASHBOARD_PATHS.NEW_EVENT)

export const openMyEvents = () => openDashboard(DASHBOARD_PATHS.MY_EVENTS)

interface OpenManageEventParams {
  eventId?: string
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openManageEvent = createAsyncAction<void, OpenManageEventParams>(
  'OPEN_MANAGE_EVENT',
  async ({eventId, multiEventWidget, calendar, recurringFilter}, {getState, dispatch, extra: {bi}}) => {
    const state = getState()

    bi.report(
      createManageEventClick({
        tab_name: 'main',
        button_name: 'manage_event',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )

    if (multiEventWidget && !isDemoEvents(state)) {
      dispatch(openMyEvents())
    } else {
      dispatch(openEventDetails(eventId))
    }
  },
)

export const openGuestList = createAsyncAction<void, string>('OPEN_GUEST_LIST', async (eventId, {dispatch}) => {
  if (eventId) {
    dispatch(openDashboard(DASHBOARD_PATHS.GUEST_LIST(eventId)))
  } else {
    dispatch(openDashboard(DASHBOARD_PATHS.MY_EVENTS))
  }
})

const openDashboard = createAsyncAction<void, string>('OPEN_DASHBOARD', async (path, {extra: {Wix, bi}}) => {
  bi.report(boLoadingStart101({}))
  Wix.Settings.openDashboard({
    state: path,
    onClose: () => {
      Wix.Settings.refreshApp()
      window.location.reload()
    },
  })
})

interface OpenBillingPageParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openBillingPage = createAsyncAction<void, OpenBillingPageParams>(
  'OPEN_BILLING_PAGE',
  async ({multiEventWidget, calendar, recurringFilter}, {getState, extra: {Wix, bi}}) => {
    bi.report(
      upgradeClick({
        origin: 'WIDGET_SETTINGS',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )
    const msid = Wix.Utils.getInstanceValue('metaSiteId')
    window.open(getBillingUrl(msid, 'settings'), '_blank')
  },
)

interface OpenPagesModalParams {
  t: TFunction
  origin: PAGES_MODAL_ORIGIN
}

export const openPagesModal = createAsyncAction<void, OpenPagesModalParams>(
  'OPEN_PAGES_MODAL',
  async ({t, origin}, {dispatch, getState, extra: {Wix, bi}}) => {
    const state = getState()
    const scheduleInstalled = state.installedApps.schedule
    const title = t('pagesModal_title')

    bi.report(
      pagesModalOpened({
        origin,
      }),
    )

    Wix.Settings.openModal(PAGES_MODAL_URL, 744, scheduleInstalled ? 358 : 254, title, data => {
      if (data) {
        const event = getActiveEvent(state)
        const eventExists = Boolean(event.id)
        const ticketed = eventExists ? isTicketed(event) : false
        const slug = eventExists ? getSlug(event) : 'demo1'
        const page = data.subPage === 'form' ? `${ticketed ? 'ticket-' : ''}form` : ''

        if (data.subPage === 'schedule') {
          Wix.Utils.navigateToSection(
            {appDefinitionId: EVENTS_APP_ID, state: slug, sectionId: SCHEDULE_SECTION_ID, queryParams: ''},
            console.log,
          )
        } else {
          dispatch(navigateToPage(`${slug}/${page}`))
        }

        Wix.Settings.closeWindow({target: 'SETTINGS'})
      }
    })
  },
)
